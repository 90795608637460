import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  FormattedItem,
  Realtime,
  RealtimeClient,
  ToolHandler,
} from 'openai-realtime-api';
import { WavRecorder } from 'wavtools';

/**
 * Type for all event logs
 */
export interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  event: {
    type: string;
    transcript?: string;
    transcription?: string;
    text?: string;
    [key: string]: any;
  };
}

interface SalesCoachEvent extends RealtimeEvent {
  coachingType: 'question' | 'insight' | 'opportunity' | 'risk';
  suggestion: string;
}

export function useRealtimeClient(
  apiKey: string,
  startTimeRef: any,
  setRealtimeEvents: React.Dispatch<React.SetStateAction<RealtimeEvent[]>>,
  wavStreamPlayerRef: any,
  wavRecorderRef: any,
  initialInstructions: string,
  tools?: [{ schema: Realtime.PartialToolDefinition; fn: ToolHandler }]
) {
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );
  const [isConnected, setIsConnected] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [items, setItems] = useState<FormattedItem[]>([]);

  // basic idempotency wrappers
  const connect = useCallback(async () => {
    const client = clientRef.current;
    if (!client.isConnected) {
      await client.connect();
    }
    setIsConnected(true);
    setItems(client.conversation.getItems());
  }, []);

  const disconnect = useCallback(async () => {
    const client = clientRef.current;
    if (client.isConnected) {
      await client.disconnect();
    }
    setIsConnected(false);
    setItems([]);
  }, []);

  // tie into the  messaging layer
  const connectConversation = useCallback(async () => {
    try {
      startTimeRef.current = new Date().toISOString();

      await connect();

      setRealtimeEvents([]);
      setItems(clientRef.current.conversation.getItems());

      clientRef.current.sendUserMessageContent([
        {
          type: `input_text`,
          text: `Hello!`,
        },
      ]);

      clientRef.current.updateSession({
        instructions: initialInstructions,
        turn_detection: {
          type: 'server_vad',
          threshold: 0.3,
          prefix_padding_ms: 100,
          silence_duration_ms: 800
        },
        input_audio_transcription: { model: 'whisper-1' }
      });

      await wavRecorderRef.current.begin();
      await wavRecorderRef.current.record((data: any) => {
        if (clientRef.current.isConnected && !isMuted) {
          clientRef.current.appendInputAudio(data.mono);
        }
      });
    } catch (error) {
      console.error('Connection error:', error);
    }
  }, [connect, setItems, isMuted]);

  const disconnectConversation = useCallback(async () => {
    try {
      await wavRecorderRef.current.end();
      await wavStreamPlayerRef.current.interrupt();
      await disconnect();
      setIsConnected(false);
    } catch (error) {
      console.error('Disconnection error:', error);
      setIsConnected(false);
    }
  }, [disconnect]);

  // SIGNIFICANT LOGIC LAYER!

  useEffect(() => {
    clientRef.current.updateSession({
      instructions: initialInstructions,
    });
    clientRef.current.updateSession({
      input_audio_transcription: { model: 'whisper-1' },
    });

    tools?.forEach((obj) => clientRef.current.addTool(obj.schema, obj.fn));

    clientRef.current.on('error', (error: any) => {
      // should be Error
      console.error(error);
      setRealtimeEvents((prev) => [
        ...prev,
        {
          time: new Date().toISOString(),
          source: 'client',
          event: { type: 'error', error: error.message },
        },
      ]);
    });

    clientRef.current.on('realtime.event', (event: any) => {
      if (event.source === 'server') {
        if (
          [
            'conversation.item.input_audio_transcription.completed',
            'response.audio_transcript.done',
            'response.cancel',
            'response.function_call_arguments.done',
          ].includes(event.event.type)
        ) {
          // Format the event data
          const formattedEvent: RealtimeEvent = {
            time: new Date().toISOString(),
            source: event.source as 'server',
            event: {
              ...event.event,
              transcript: event.event.transcript || event.event.transcription || event.event.text
            }
          };
          
          setRealtimeEvents(prev => [...prev, formattedEvent]);
        }
      } else if (event.source === 'client') {
        if (event.event.type !== 'input_audio_buffer.append') {
          const formattedEvent: RealtimeEvent = {
            time: new Date().toISOString(),
            source: 'client',
            event: {
              ...event.event,
              transcript: event.event.transcript || event.event.transcription || event.event.text
            }
          };
          
          setRealtimeEvents(prev => [...prev, formattedEvent]);
        }
      }
    });

    clientRef.current.on('conversation.updated', async ({ item, delta }) => {
      const items = clientRef.current.conversation.getItems();
      
      // Skip audio processing entirely
      if (item.status === 'completed') {
        // Only update items, don't process audio
        setItems(items);
      }
    });

    clientRef.current.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await clientRef.current.cancelResponse(trackId, offset);
      }
    });

    // Cleanup function to remove all event listeners and tools when component unmounts
    return () => {
      // Remove all tools
      tools?.forEach((obj) => clientRef.current.removeTool(obj.schema.name));
      
      // Remove all event listeners
      clientRef.current.off('error');
      clientRef.current.off('realtime.event');
      clientRef.current.off('conversation.updated');
      clientRef.current.off('conversation.interrupted');
    };
  }, []); // Empty dependency array since we want this to run only once

  // Create a memoized setMuted function that handles the recorder state
  const setMuted = useCallback(async (muted: boolean) => {
    setIsMuted(muted);
    if (muted) {
      await wavRecorderRef.current.end();
    } else {
      await wavRecorderRef.current.begin();
      await wavRecorderRef.current.record((data: any) => {
        if (clientRef.current.isConnected && !muted) {
          clientRef.current.appendInputAudio(data.mono);
        }
      });
    }
  }, []);

  // Add this to the existing event processing logic
  const processCoachingEvent = (event: any): SalesCoachEvent | null => {
    if (event.type === 'response.audio_transcript.done') {
      // Analyze the transcript and categorize the coaching suggestion
      const transcript = event.transcript || '';
      
      // Simple categorization logic (expand based on your needs)
      if (transcript.includes('should ask')) {
        return {
          ...event,
          coachingType: 'question',
          suggestion: transcript
        };
      }
      // Add other categorization logic
      
      return null;
    }
    return null;
  };

  return {
    client: clientRef.current,
    isConnected,
    isMuted,
    setIsMuted: setMuted,
    items,
    connectConversation,
    disconnectConversation,
  };
}
