import { useRef, useEffect } from 'react';

interface TranscriptViewProps {
  title: string;
  entries: Array<{
    time: string;
    text: string;
    type?: 'insight' | 'question' | 'opportunity' | 'risk';
  }>;
}

export function TranscriptView({ title, entries }: TranscriptViewProps) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const typeColors = {
    insight: 'text-purple-600',
    question: 'text-blue-600',
    opportunity: 'text-green-600',
    risk: 'text-red-600'
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [entries]);

  return (
    <div className="flex flex-col h-full bg-white rounded-2xl">
      <div className="p-4 border-b border-gray-100">
        <h2 className="text-[15px] font-medium text-gray-900">{title}</h2>
      </div>
      
      <div ref={scrollRef} className="flex-1 overflow-y-auto p-4 space-y-6">
        {entries.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <span className="text-sm text-gray-400">No messages yet</span>
          </div>
        ) : (
          entries.map((entry, i) => (
            <div key={i} className="space-y-1">
              <div className="flex items-center justify-between gap-4">
                <span className="text-xs font-mono text-gray-400">
                  {entry.time}
                </span>
                {entry.type && (
                  <span className={`
                    text-xs font-medium uppercase tracking-wide
                    ${typeColors[entry.type]}
                  `}>
                    {entry.type}
                  </span>
                )}
              </div>
              <div className={`
                text-sm leading-relaxed text-gray-900
                ${entry.type ? 'pl-4 border-l-2 border-gray-100' : ''}
              `}>
                {entry.text}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
