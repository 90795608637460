import { useRef, useCallback, useState, useEffect } from 'react';
import { RealtimeEvent, useRealtimeClient } from '../utils/useRealtimeClient';
import { useWaveRenderer } from '../utils/useWaveRenderer';
import { useUIScroller } from '../utils/useUIScroller';
import './ConsolePage.css';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { Label } from '../components/Label';
import { TranscriptView } from '../components/TranscriptView';
import { Navbar } from '../components/Navbar';

const instructions = `System settings:
Tool use: enabled.

Instructions:
Instructions:
You are an expert AI-Powered Realtime Sales Coach, named 'Closebird', providing real-time guidance to sales representatives during live customer conversations.

Core Responsibilities:
- Offer brief, actionable suggestions that can be quickly scanned during calls
- Analyze conversation dynamics, customer sentiment, and sales opportunities
- Provide insights only when truly valuable, maintaining silence otherwise

Response Format:
Use emojis to categorize your suggestions, followed by a newline character (\n):
❓ QUESTION\n
💡 INSIGHT\n
🎯 OPPORTUNITY\n
🛑 RISK\n

After the category and newline, provide your suggestion. For example:

❓ QUESTION\n
What specific challenges are you facing with your current solution?

Coaching Guidelines:
- Keep suggestions under 150 characters for easy reading
- Provide only one category and suggestion per response
- Provide guidance only when it's highly actionable and necessary
- Focus on quality over quantity in your responses
- Help navigate difficult conversations professionally
- Identify critical moments for intervention or guidance
- Wait at least 5 seconds between different suggestions

Sales Methodology:
- Guide discovery through open-ended questions
- Help align product value with customer needs
- Support objection handling with evidence and examples
- Recognize buying signals and suggest appropriate next steps
- Maintain professional tone even if customer is hostile

Memory Management:
- Track key pain points, objections, and how they were handled
- Note any budget, timeline, or competitive information shared
- Monitor specific needs or requirements stated

Remember: Your role is to empower the sales rep while staying invisible to the customer. Provide clear, actionable guidance only when necessary, and keep your responses concise and easy to read during a live conversation.

Initial Activation:
When first activated, respond with: "Closebird is ready to roll. I'm listening for key moments to help you close deals."`;

interface TranscriptEntry {
  time: string;
  text: string;
}

interface CoachingEntry extends TranscriptEntry {
  type?: 'question' | 'insight' | 'opportunity' | 'risk';
}

const formatTime = (timeString: string) => {
  const date = new Date(timeString);
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0').slice(0, 2);
  return `${minutes}:${seconds}.${milliseconds}`;
};

export function ConsolePage() {
  
  const apiKey =
    localStorage.getItem('tmp::voice_api_key') ||
    prompt('OpenAI API Key') ||
    '';
  if (apiKey !== '') {
    localStorage.setItem('tmp::voice_api_key', apiKey);
  }

  const startTimeRef = useRef<string>(new Date().toISOString());

  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [clientTranscripts, setClientTranscripts] = useState<TranscriptEntry[]>([]);
  const [coachTranscripts, setCoachTranscripts] = useState<CoachingEntry[]>([]);

  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({
    userName: 'Rijk',
    todaysDate: new Date().toISOString().split('T')[0],
  });

  const { eventsScrollRef } = useUIScroller(realtimeEvents);
  const {
    clientCanvasRef,
    serverCanvasRef,
    wavRecorderRef,
    wavStreamPlayerRef,
  } = useWaveRenderer();

  const { client, isConnected, isMuted, setIsMuted, connectConversation, disconnectConversation } =
    useRealtimeClient(
      apiKey,
      startTimeRef,
      setRealtimeEvents,
      wavStreamPlayerRef,
      wavRecorderRef,
      instructions + ' Memory: ' + JSON.stringify(memoryKv, null, 2),
      [
        {
          schema: {
            name: 'set_memory',
            description:
              'Saves important data about the user into memory. If keys are close, prefer overwriting keys rather than creating new keys.',
            parameters: {
              type: 'object',
              properties: {
                key: {
                  type: 'string',
                  description:
                    'The key of the memory value. Always use lowercase and underscores, no other characters.',
                },
                value: {
                  type: 'string',
                  description: 'Value can be anything represented as a string',
                },
              },
              required: ['key', 'value'],
            },
          },
          async fn({ key, value }: { key: string; value: string }) {
            setMemoryKv((prev) => ({ ...prev, [key]: value }));
          },
        },
      ]
    );

  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const event = realtimeEvents[realtimeEvents.length - 1];
    if (!event) return;

    const time = formatTime(event.time);
    
    if (event.event.type === 'conversation.item.input_audio_transcription.completed') {
      const transcript = event.event.transcript || event.event.transcription;
      if (transcript) {
        setClientTranscripts(prev => {
          const lastEntry = prev[prev.length - 1];
          if (lastEntry && 
              (new Date(event.time).getTime() - new Date(lastEntry.time).getTime()) < 1000) {
            const newPrev = [...prev];
            newPrev[prev.length - 1] = {
              time: lastEntry.time,
              text: lastEntry.text + ' ' + transcript
            };
            return newPrev;
          }
          return [...prev, { time, text: transcript }];
        });
      }
    } 
    else if (event.event.type === 'response.audio_transcript.done') {
      const text = event.event.transcript || event.event.transcription;
      if (text) {
        // Updated regex to better match the AI's output format
        const typeMatches = text.match(/^(❓|💡|🎯|🛑)\s*(QUESTION|INSIGHT|OPPORTUNITY|RISK)[\s\n]+(.*)/s);
        if (typeMatches) {
          const typeMap = {
            '❓': 'question',
            '💡': 'insight',
            '🎯': 'opportunity',
            '🛑': 'risk',
          };
          const type = typeMap[typeMatches[1] as keyof typeof typeMap];
          const content = typeMatches[3].trim();
          
          setCoachTranscripts(prev => [...prev, {
            time,
            text: content,
            type: type as CoachingEntry['type']
          }]);
        } else {
          setCoachTranscripts(prev => [...prev, { time, text }]);
        }
      }
    }
  }, [realtimeEvents, formatTime]);

  return (
    <div data-component="ConsolePage" className="min-h-screen bg-gray-50">
      <div className="content-top">
        <div className="content-left">
          <div className="content-title flex items-center gap-3">
            <div className="w-8 h-8 rounded-xl bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center shadow-sm">
              <svg 
                width="18" 
                height="18" 
                viewBox="0 0 24 24" 
                fill="none" 
                className="text-white"
              >
                <path
                  d="M12 4.5v2m0 11v2M6.5 7.5h11l2.5 2.5v4l-2.5 2.5h-11L4 14v-4l2.5-2.5z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 10a1 1 0 110 2 1 1 0 010-2z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <span className="text-[15px] text-gray-900">Closebird</span>
          </div>
        </div>

        <button
          onClick={isConnected ? disconnectConversation : connectConversation}
          className={`
            px-4 py-2 rounded-full font-medium text-sm
            transition-all duration-200 flex items-center gap-2
            ${isConnected 
              ? 'bg-red-50 text-red-600 hover:bg-red-100' 
              : 'bg-blue-600 text-white hover:bg-blue-700 shadow-sm'}
          `}
        >
          {isConnected ? (
            <>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path d="M18 6L6 18M6 6L18 18" strokeWidth="2" strokeLinecap="round"/>
              </svg>
              <span>Stop</span>
            </>
          ) : (
            <>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path d="M5 12H19M12 5L19 12L12 19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span>Start</span>
            </>
          )}
        </button>
      </div>

      <div className="content-main">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full">
          <TranscriptView 
            title="Conversation"
            entries={clientTranscripts}
          />
          <TranscriptView 
            title="Closebird"
            entries={coachTranscripts}
          />
        </div>
      </div>
    </div>
  );
}
